/**
*   Style for Header
**/

#header {
  border-bottom: #d0d0d0;
  border-bottom-style: solid;
  border-bottom-width: 1px;
}

.navbar-nav > li  {
  text-align: center;
  width: 100%;
  cursor: pointer;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 1.5em;
  font-weight: 300;
  letter-spacing: 1px;
}

.navbar-toggler:focus,
.navbar-toggler:active,
.navbar-toggler-icon:focus {
  outline: none;
  box-shadow: none;
}

/**
* Styles for Main Page
**/

.full-page-top {
  position: relative;
  color: white;
  z-index: 5;
  width: 100%;
  overflow: hidden;
  text-align: center;
  background: #00000000;
}

.header-height {
  height: 100vh;
}

.double-header-height {
  height: 200vh;
}

.name-style {
  font-size: 4em;
  letter-spacing: 4px;
  font-weight: 600;
  margin-bottom: 16px;
  border-width: 2px;
  border-style: solid;
  display: inline-block;
  padding: 0px 45px;
}

.subname-style {
  font-size: 1.4em;
  letter-spacing: 3px; 
  font-weight: 400;
  padding-bottom: 8px;
  margin-top: 30px;
  margin-bottom: 20px;
  border-bottom-style: solid;
  border-bottom-width: 4px;
} 

.description-text-style {
  letter-spacing: 1.5px;
  font-weight: 400;
  line-height: 200%;
  padding-right: 5px;
  margin-bottom: 20px;
  text-align: justify;
}

.inherit {
  text-decoration: inherit;
  color: inherit;
}

.inherit:active {
  text-decoration: none;
  background-color: #00000000;
}

.inherit:hover {
  color: inherit;
  text-decoration: underline;
}

td {
  vertical-align: top;
  padding-bottom: 10px;
}

.side-nav-item {
  background-color: #222222;
  color: white;
  text-align: center;
  cursor: pointer;
  width: 120px;
  height: 70px;
  margin-right: 20px;
  font-size: 1em;
  right: 0;
  z-index: 2;
}

.vertical-center {
  position: absolute;
  width: 100%;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.top-arrow {
  animation-name: translate-arrow;
  animation-duration: 2s;
  animation-iteration-count: infinite;
}

@keyframes translate-arrow {
  0% { transform: translateY(-2px); }
  50% { transform: translateY(4px); }
  100% { transform: translateY(-2px); }
}

.sticky-header {
  width: 120px;
  position: sticky;
  position: -webkit-sticky;
  top: 0px; 
}

/*
* Styles for categories
*/

.category-header {
  font-size: 3em;
  color: #1593CB;
  letter-spacing: 2px;
  font-weight: 300;
  padding-top: 60px;
  margin-bottom: 20px;
}

.category-description {
  margin-bottom: 10px;
  font-size: 1.2em;
  letter-spacing: 1.5px;
  font-weight: 400;
  color: #5a5a5a;
}

.border-down {
  border-color: #AAA;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  margin-bottom: 40px;
}

.project-title {
  font-size: 1.5em;
  color: #1593CB;
  letter-spacing: 1px;
  font-weight: 600;
  margin: 20px 40px;
}

.project-description {
  letter-spacing: 1.5px;
  font-weight: 400;
  line-height: 150%;
}

.project-technologies {
  margin-top: 20px;
  font-size: 0.85em;
}

.project-technologies > span {
  letter-spacing: 0.5px;
  color: #555;
}

.project-margin-bottom {
  margin-bottom: 100px;
}

.website-screenshot {
  border-style: solid;
  border-width: 1px;
  border-color: #777;
  cursor: pointer;
  transition: 0.5s ease
}

.website-screenshot:hover {
  transform: scale(1.05);
  filter: brightness(1.1);
}

.screen-align-text {
  text-align: right;
}

.description-link {
  color: gray;
}

.description-link:hover {
  color: black;
}

.experience-background {
  background-color: #F3EFE0;
}

.experience-text {
  text-align: right;
}

.education-background {
  background-color: #F7F7F7;
}


.edu-list > li {
  margin-bottom: 6px;
}

.edu-list {
  margin-top: 0px;
  margin-bottom: 0px;
}

.edu-list-collapsed {
  max-height: 0;
  transition: max-height 500ms ease-out;
}

.edu-list-expanded {
  max-height: 10000px;
  transition: max-height 2000ms ease-out;
}

.abilities-background {
  background-color: #F3EFE0;
}

.footer-background {
  height: 100vh;
  background-color: #434242;
}


#topVideo {
  position: absolute;
  margin-bottom: -4px;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: -1;
}

#topVideo > video, #topVideo > img {
  object-fit: cover;
  object-position: bottom center;
  width: 100%;
  height: 100vh; 
}

/*
* Footer
*/

.footer-icons {
  font-size: 1.5em; 
  letter-spacing: 2px;
  font-weight: 400;
  text-align: left;
  color: white;
  margin-top: 5px; 
}

.main-content {
  margin-top: -280px;
  direction: ltr;
}

@media(max-width: 1200px) {

  .side-nav-item {
    width: 80px;
    height: 50px;
    margin-right: 8px;
    font-size: 0.85em;
  }

  .main-content {
    margin-top: -200px;
  }

  .sticky-header {
    width: 100px;
  }

}

@media(max-width: 992px) {
  .description-text-style {
    margin-bottom: 10px;
  }

  .project-title {
    margin: 20px 0px;
  }

  .project-description {
    text-align: justify;
  }

  .website-screenshot {
    margin: 10px 20px 20px 20px;
  }

  .screen-align-text {
    text-align: left;
  }

  .main-content{
    margin-top: 0px;
  }

  .side-nav-item {
    margin-right: 20px;
  }
}

@media (max-width: 768px) {
  .header-height {
    height: calc(var(--vh, 1vh) * 100);
  }

  .double-header-height {
    height: calc(var(--vh, 1vh) * 200);
  }

  #topVideo > video, #topVideo > img {
    height: calc(var(--vh, 1vh) * 100);
  }

  .name-style {
    font-size: 2em;
    letter-spacing: 2px;
    font-weight: 600;
    margin-bottom: 8px;
    padding: 0px 25px;
  }

  .subname-style {
    font-size: 1em;
    letter-spacing: 2px; 
    padding-bottom: 4px;
    margin-top: 16px;
    margin-bottom: 16px;
  } 

  .description-text-style {
    letter-spacing: 1px;
    font-size: 0.9em;
    line-height: 170%;
    padding-right: 12px;
  }

  .experience-text {
    text-align: left;
  }

  .footer-icons {
    font-size: 1em;
  }
}

@media (max-width: 576px) {
  .footer-icons {
    font-size: 0.8em;
  }

  .project-margin-bottom {
    margin-bottom: 60px;
  }

  td {
    padding-bottom: 2px;
  }
}

@media (max-height: 420px) {
  .header-height {
    height: calc(var(--vh, 1vh) * 250);
  }

  .double-header-height {
    height: calc(var(--vh, 1vh) * 500);
  }

  #topVideo > video, #topVideo > img {
    height: calc(var(--vh, 1vh) * 250);
  }

  .footer-background {
    height: 150vh;
  }
}